import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                The <a href='https://astralbabes.ai' target='_blank'>Astral Babes</a> had always known that their mission on Earth was of great importance. They had come to raise the spiritual vibration of humanity, and had worked tirelessly to fill the Bitcoin blockchain with their divine feminine energy. But they soon realized that their efforts were not enough. The blockchain was still vulnerable, and in need of additional protection.
            </p>
            <p>
                That’s when the call went out to the Astral Chads. The Babes knew that the Chads' divine masculine energy was exactly what was needed to boost the blockchain’s security budget and safeguard it from harm. And so, the Chads heeded the call, leaving Hiranyaloka to join forces with the Astral Babes on Earth.
            </p>
            <p>
                Together the Astral Chads and Babes are working to elevate bitcoin to new heights, creating a powerful union of masculine and feminine energy that will bring forth a new era of spiritual growth and prosperity.
            </p>
        </>
    )
}

export default Lore